import { Component, Host, Prop, h } from '@stencil/core';

@Component({
  tag: 'x-header-menu',
  styleUrl: 'x-header-menu.sass',
})
export class XHeaderMenu {
  @Prop() container: boolean;
  @Prop() group: boolean;
  @Prop() item: boolean;
  @Prop() href: string;
  @Prop() color: string;

  render() {
    if (this.container || this.group) {
      return (
        <Host>
          <slot></slot>
        </Host>
      );
    } else if (this.item) {
      return (
        <Host>
          <a href={this.href}>
            <slot></slot>
          </a>
        </Host>
      );
    } else {
      return null;
    }
  }
}
