x-header-menu
  display: block

x-header-menu[group]
  border-bottom: 1px solid var(--divider-dark-grey)
  padding: 8px 16px

x-header-menu[item]
  +preset(3)
  padding: 6px 0
  a
    color: var(--on-input-white-20)
  &[color="red"] a
    color: var(--input-error)
  a:hover
    color: var(--on-input-green)
